import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import RTVErrorDialog from '../../../commons/dialogs/RTVErrorDialog';

import {
  LOGIN_REQUESTED,
  LOGIN_ERRORED,
  LOGIN_SUCCESSFULL,
  LOGIN_DISABLED_SUCCESSFULL,
  ENABLE_USER_CANCELLED,
  ENABLE_USER_REQUESTED,
  ENABLE_USER_ERRORED
} from '../../../redux/actions/types';
import { login } from '../../../redux/actions/auth';
import { enableUser } from '../../../redux/actions/user';
import AdornedButton from '../../../commons/AdornedButton';
import RTVDialog from '../../../commons/dialogs/RTVDialog';
import { withTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import RtvButton from '../../../commons/Buttons/RtvButton';

const styles = theme => ({
  fields: {
    margin: theme.spacing(2, 0, 2, 0),
    width: '100%'
  },
  form: {
    width: '100%',
    height: '100%',
    maxHeight: '250px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  signInButton: {
    width: '200px',
    margin: theme.spacing(1)
  },
  forgetPassword: {
    cursor: 'pointer',
    alignSelf: 'flex-start',
    textDecoration: 'underline',
    marginBottom: '16px'
  }
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        username: '',
        password: ''
      },
      dirty: false,
      errorDialog: false,
      error: null,
      loading: false,
      enableDialog: false,
      loadingEnableDialog: false
    };
    this.changeTypes = { USERNAME: 'username', PASSWORD: 'password' };
    this.handleChanges = this.handleChanges.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.status !== this.props.status) {
      if (this.props.status === LOGIN_REQUESTED) {
        this.setState({ loading: true });
      }
      if (this.props.status === LOGIN_SUCCESSFULL) {
        this.setState({ loading: false });
      }
      if (this.props.status === LOGIN_ERRORED) {
        this.setState({
          errorDialog: true,
          error: this.props.loginError,
          loading: false
        });
      }
      if (this.props.status === LOGIN_DISABLED_SUCCESSFULL) {
        this.setState({
          enableDialog: true,
          loading: false
        });
      }
    }

    if (prevProps.userStatus !== this.props.userStatus) {
      if (this.props.userStatus === ENABLE_USER_REQUESTED) {
        this.setState({ loadingEnableDialog: true });
      }
      if (this.props.userStatus === ENABLE_USER_ERRORED) {
        this.setState({ loadingEnableDialog: false });
      }
    }
  }

  handleDialogClose = () => {
    this.setState({ errorDialog: false, error: null });
  };

  handleChanges(changeType, { target: { value } }) {
    this.setState(prevState => ({
      ...prevState,
      user: {
        ...prevState.user,
        [changeType]: value
      }
    }));
  }

  handleLogin(event) {
    event.preventDefault();
    this.setState(prevState => ({ ...prevState, dirty: true }));
    const { user } = this.state;

    this.props.login(user.username, user.password);
  }

  render() {
    const { classes } = this.props;
    const { user } = this.state;

    return (
      <>
        <RTVErrorDialog
          open={this.state.errorDialog}
          onClose={this.handleDialogClose}
          error={this.state.error}
          action="login"
        />
        <RTVDialog
          open={this.state.enableDialog}
          dialogProps={{
            disableBackdropClick: true,
            disableEscapeKeyDown: true
          }}
          title="Volviste! :)"
          message="Querés reactivar tu usuario?"
          actionSection={
            <>
              <AdornedButton
                variant="contained"
                color="secondary"
                onClick={() => {
                  this.props.enableUserCancel();
                  this.setState({ enableDialog: false });
                }}
                disabled={this.state.loadingEnableDialog}
              >
                No
              </AdornedButton>
              <AdornedButton
                variant="contained"
                color="primary"
                onClick={() => this.props.enableUser()}
                loading={this.state.loadingEnableDialog}
                disabled={this.state.loadingEnableDialog}
              >
                Sí! Continuar
              </AdornedButton>
            </>
          }
        />
        <form className={classes.form} onSubmit={this.handleLogin}>
          <TextField
            color="secondary"
            className={classes.fields}
            fullWidth
            label="Mail o Apodo"
            id="user-input"
            onChange={e => this.handleChanges(this.changeTypes.USERNAME, e)}
            value={user.username}
            InputLabelProps={{
              shrink: true
            }}
            autoFocus
            required
          />
          <TextField
            color="secondary"
            className={classes.fields}
            fullWidth
            type="password"
            label="Contraseña"
            id="password-input"
            onChange={e => this.handleChanges(this.changeTypes.PASSWORD, e)}
            value={user.password}
            InputLabelProps={{
              shrink: true
            }}
            required
          />
          <Typography
            className={classes.forgetPassword}
            component={NavLink}
            to="/recuperacion"
            color="secondary"
          >
            Olvidaste tu contraseña?
          </Typography>
          <RtvButton
            variant="rtvRed"
            className={classes.signInButton}
            type="submit"
            loading={this.state.loading}
            disabled={this.state.loading}
          >
            {this.props.t('login')}
          </RtvButton>
        </form>
      </>
    );
  }
}

const mapStateToProps = state => ({
  loginError: state.auth.error,
  status: state.auth.status,
  userStatus: state.user.status
});

const mapDispatchToProps = dispatch => ({
  login: (user, password) => dispatch(login(user, password)),
  enableUser: () => dispatch(enableUser()),
  enableUserCancel: () => dispatch({ type: ENABLE_USER_CANCELLED })
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Login))
);
