import { createMuiTheme } from '@material-ui/core/styles';
import BrandonGrotesqueRegular from './styling/fonts/Brandon_reg.woff';
import BrandonGrotesqueMedium from './styling/fonts/Brandon_med.woff';
import BrandonGrotesqueLight from './styling/fonts/Brandon_light.woff';
import BrandonGrotesqueBold from './styling/fonts/Brandon_bld.woff';

const brandomLight = {
  fontFamily: 'BrandomGrotesque',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 200,
  src: `
    local('Brandom Grotesque'),
    local('Brandom-Grotesque-Light'),
    url(${BrandonGrotesqueLight}) format('woff')
  `
};

const brandomRegular = {
  fontFamily: 'BrandomGrotesque',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Brandom Grotesque'),
    local('Brandom-Grotesque-Regular'),
    url(${BrandonGrotesqueRegular}) format('woff')
  `
};

const brandomMedium = {
  fontFamily: 'BrandomGrotesque',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `
    local('Brandom Grotesque'),
    local('Brandom-Grotesque-Medium'),
    url(${BrandonGrotesqueMedium}) format('woff')
  `
};

const brandomBold = {
  fontFamily: 'BrandomGrotesque',
  fontStyle: 'bold',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
    local('Brandom Grotesque'),
    local('Brandom-Grotesque-Bold'),
    url(${BrandonGrotesqueBold}) format('woff')
  `
};

const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
      disableTouchRipple: true
    }
  },
  shape: {
    borderRadius: 15
  },
  sizes: {
    singleScreenDesktopHeight: 'calc(100vh - 80px)',
    singleScreenMobileHeight: 'calc(100vh - 56px)'
  },
  palette: {
    primary: {
      light: '#f7ece2',
      main: '#f15f67',
      background: '#ebebeb'
    },
    text: {
      primary: '#414042'
    },
    secondary: {
      other: '#f8f8f8',
      light: '#a8a6a6',
      main: '#707070',
      dark: '#414042',
      contrastText: '#fdfbfc'
    },
    yellow: {
      background: '#f8f2c4',
      lighter: '#FCF9E2',
      light: '#F6F5B7',
      main: '#EEDF6C',
      dark: '#e8d85f'
    },
    orange: {
      light: '#fcc874',
      main: '#EDBB6A',
      dark: '#eac944'
    },
    green: {
      darker: '#7FB2AC',
      dark: '#7FB2AC',
      main: '#A7D6A2',
      light: '#EDF6ED'
    },
    gradients: {
      showroom: ['#B0CAFB', '#AFE1BC'],
      premium: ['#FAACA8', '#BE6DF7'],
      celebrity: ['#FAD2A5', '#EE6184'],
      vip: ['#B2C8FD', '#F3BDBD'],
      regular: ['#F4ABA7', '#FEF0AD']
    }
  },
  typography: {
    fontFamily: 'BrandomGrotesque, Arial',
    button: {
      fontWeight: 700,
      textTransform: 'none'
    }
  },
  overrides: {
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: '$labelColor'
        }
      }
    },
    MuiInputBase: {
      input: {
        padding: '6px 0 2px 0',
        fontSize: '16px',
        lineHeight: '17px'
      }
    },
    MuiInputLabel: {
      root: {
        color: '#414042',
        fontSize: '22px'
      },
      asterisk: {
        display: 'none'
      }
    },
    MuiButton: {
      root: {
        fontSize: '1rem'
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none'
        },
        '&:active': {
          boxShadow: 'none'
        }
      }
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#fff'
        },
        '#announcements': {
          display: 'none'
        },
        'a.MuiButton-root': {
          display: '-webkit-box',
          '-webkit-box-align': 'center',
          '-webkit-box-orient': 'block-axis',
          '& .MuiButton-label': {
            width: 'unset'
          }
        },
        '.MuiButton-root': {
          padding: '6px 16px 6px 16px'
        },
        '.MuiButton-label': {
          padding: 0,
          fontSize: '0.9rem'
        },
        'label + .MuiInput-formControl': {
          marginTop: '21px'
        },
        '.MuiButton-contained:hover': {
          boxShadow: 'none'
        },
        '.MuiButton-contained:active': {
          boxShadow: 'none'
        },
        '.MuiPaper-rounded': {
          borderRadius: '5px !important',
          boxShadow: '0 3px 15px 0 rgba(0, 0, 0, 0.1) !important',
          backgroundColor: '#ffffff'
        },
        '.MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track': {
          backgroundColor: '#9e9e9e !important'
        },
        '.MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track': {
          opacity: '1 !important'
        },
        '@font-face': [brandomLight, brandomRegular, brandomMedium, brandomBold]
      }
    }
  }
});

export default theme;
