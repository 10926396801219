import {
  HEART_BEAT_FAILED,
  HEART_BEAT_REQUESTED,
  HEART_BEAT_RETRIEVED
} from './types';
import AuthRestClient from '../../http/client/Authentication';

export const APP_EXPRESS_TOGGLE = 'APP::EXPRESS_TOGGLE';
export const APP_PROFILE_SETUP_TOGGLE = 'APP::PROFILE_SETUP_TOGGLE';

// `STATE_REGISTERING`
// `STATE_WELCOME`
// `STATE_APP`
// `STATE_MAINTENANCE`
export const setAppState = state => dispatch => {
  dispatch({ type: state });
};

export const heartbeat = () => {
  const requested = () => ({ type: HEART_BEAT_REQUESTED });
  const successfull = response => ({
    type: HEART_BEAT_RETRIEVED,
    heartbeat: response.data
  });
  const errored = error => ({ type: HEART_BEAT_FAILED, error });

  return dispatch => {
    dispatch(requested());

    return AuthRestClient.heartbeat()
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

/**
 * Dispatches a `APP_EXPRESS_TOGGLE` action that will open
 * the EXPRESS modal that will allow the user to login or
 * register in a faster way.
 *
 * @param {boolean} open
 * @param {object} fromAction
 * The object `fromAction` is an object that right now
 * will only care about a product. A like, purchase, add to cart
 * and these types of action will come from a user trying to do an
 * action on a `Product`.
 * example:
 * ```
 * {
 *    entity: 'Product',
 *    identifier: '<Product.id>',
 *    payload: null,
 * }
 * ```
 * @returns
 */
export const expressToggle = (open, fromAction = null) => dispatch => {
  dispatch({ type: APP_EXPRESS_TOGGLE, payload: { open, fromAction } });
};

export const profileSetupToggle = open => dispatch => {
  dispatch({ type: APP_PROFILE_SETUP_TOGGLE, open });
};
