import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import lodash from 'lodash';
import { like, unlike } from '../redux/actions/favourite';
import { withRouter } from 'react-router';
import { expressToggle } from '../redux/actions/app';

class RTVFavouriteButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      product: props.product,
      favourite: false,
      loading: false,
      justActed: false,
      initialValue: false
    };
  }

  componentDidMount() {
    const { favourites } = this.props.localStats;
    if (favourites) {
      const favourite = favourites.includes(this.state.product.id);
      this.setState({
        favourite,
        initialValue: favourite
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!lodash.isEqual(prevProps.localStats, this.props.localStats)) {
      if (!this.props.localStats.favourites) {
        return;
      }
      this.setState({
        favourite: this.props.localStats.favourites.includes(
          this.state.product.id
        )
      });
    }
    if (!lodash.isEqual(prevProps.favouriteState, this.props.favouriteState)) {
      if (this.props.favouriteState.product !== this.state.product.id) {
        return;
      }

      this.setState(state => ({
        ...state,
        loading: false,
        justActed:
          !this.props.favouriteState.error &&
          this.props.favouriteState.favourite !== this.state.initialValue
      }));
    }
  }

  clickHandler = () => {
    if (this.state.loading) {
      return;
    }
    if (!this.props.isLoggedIn) {
      this.props.expressToggle(true, {
        entity: 'Product',
        identifier: this.props.product?.id
      });
      return;
    }
    this.setState({ loading: true }, () => {
      if (this.state.favourite) {
        this.props.unlike(this.props.product);
      } else {
        this.props.like(this.props.product);
      }
    });
  };

  render() {
    if (this.props.children instanceof Function) {
      return this.props.children({
        onClick: this.clickHandler,
        loading: this.state.loading,
        favourite: this.state.favourite,
        justActed: this.state.justActed
      });
    }
    return <></>;
  }
}

const mapStateToProps = state => ({
  isLoggedIn: state.auth.isLoggedIn,
  favouriteState: state.favourite.favouriteState,
  localStats: state.user.localStats
});

const mapDispatchToProps = dispatch => ({
  push: path => dispatch(push(path)),
  like: product => dispatch(like(product)),
  unlike: product => dispatch(unlike(product)),
  expressToggle: (toggle, fromAction) =>
    dispatch(expressToggle(toggle, fromAction))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RTVFavouriteButton));
