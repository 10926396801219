import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const VERIFICATIONS = {
  EMAIL: 'email',
  PHONE: 'phone'
};

// `children`: Component that we are protecting.
// `user`: Current value for `user` in the `auth` reducer which
// we use to know if a user is currently logged in.
// `verification`: Is the max verification needed to reach the site,
// you could access the "email verification" without a "phone verification"
// but not the other way around.
// We can call this the "AuthenticationLevel"
// Where PHONE_VERIFIED > EMAIL_VERIFIED > REGISTERED_USER.
const AuthRoute = ({
  children,
  component,
  user,
  verification,
  privateStats,
  ...rest
}) => {
  let IS_LOGGED_IN = user;
  // We should be using privateStats for this since we request them when we
  // load the page, so if the user verified their account while they were on
  // the verificiation page on the computer, they don't have to logout for
  // them to have their account correctly verified.
  let EMAIL_VERIFIED = user ? user.emailVerified : false;
  if (!EMAIL_VERIFIED) {
    EMAIL_VERIFIED = privateStats ? privateStats.emailVerified : false;
  }
  let PHONE_VERIFIED = user ? user.phoneVerified : false;
  if (!PHONE_VERIFIED) {
    PHONE_VERIFIED = privateStats ? privateStats.phoneVerified : false;
  }

  const ROUTES = {
    LOGIN: '/login',
    VERIFY_EMAIL: '/validar-email',
    VERIFY_PHONE: '/validar-telefono',
    HOME: '/'
  };

  let redirectRoute = null;

  if (IS_LOGGED_IN) {
    if (!EMAIL_VERIFIED && verification === VERIFICATIONS.EMAIL) {
      redirectRoute = ROUTES.VERIFY_EMAIL;
    } else if (!PHONE_VERIFIED && verification === VERIFICATIONS.PHONE) {
      redirectRoute = ROUTES.VERIFY_PHONE;
    }
  } else {
    if (rest.path !== '/home') {
      redirectRoute = `${ROUTES.LOGIN}?from=${encodeURI(
        rest.location.pathname + rest.location.search
      )}`;
    }
  }

  if (rest.path === '/validar-email' && EMAIL_VERIFIED) {
    redirectRoute = ROUTES.VERIFY_PHONE;
  }
  if (rest.path === '/validar-telefono' && PHONE_VERIFIED) {
    redirectRoute = ROUTES.HOME;
  }

  const Children = component || children;

  return (
    <Route
      {...rest}
      render={() =>
        redirectRoute ? <Redirect to={redirectRoute} /> : <Children />
      }
    />
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
  privateStats: state.user.privateStats
});

export default connect(mapStateToProps)(AuthRoute);
