import React from 'react';
import LoginForm from '../login/LoginForm';
import { useExpressModalStyles } from './ExpressModal';
import { Typography } from '@material-ui/core';

const LoginStep = ({ onChangeStep }) => {
  const classes = useExpressModalStyles();

  return (
    <div style={{ width: '275px' }} className={classes.dialogContent}>
      <Typography className={classes.title}>Ya tenés cuenta?</Typography>
      <LoginForm />

      <Typography
        style={{
          cursor: 'pointer',
          textDecoration: 'underline',
          color: '#000',
          marginTop: '8px'
        }}
        color="secondary"
        onClick={() => onChangeStep('RegisterStep')}
      >
        No tenés cuenta? Registrate
      </Typography>
    </div>
  );
};

export default LoginStep;
