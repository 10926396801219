import React, { Component } from 'react';
import lodash from 'lodash';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { follow, unfollow } from '../redux/actions/social';
import { withRouter } from 'react-router';
import { expressToggle } from '../redux/actions/app';

class RTVFollowButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      following: false,
      loading: false,
      justActed: false,
      initialValue: false
    };
  }

  componentDidMount() {
    const { followings } = this.props.localStats;
    if (followings) {
      const following = followings.includes(this.state.user);
      this.setState({
        following,
        initialValue: following
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!lodash.isEqual(prevProps.localStats, this.props.localStats)) {
      if (!this.props.localStats.followings) {
        return;
      }
      this.setState({
        following: this.props.localStats.followings.includes(this.state.user)
      });
    }

    if (!lodash.isEqual(prevProps.followState, this.props.followState)) {
      if (this.props.followState.user !== this.state.user) {
        return;
      }

      this.setState(state => ({
        ...state,
        loading: false,
        justActed:
          !this.props.followState.error &&
          this.props.followState.following !== this.state.initialValue
      }));
    }
  }

  clickHandler = () => {
    if (!this.props.isLoggedIn) {
      this.props.expressToggle(true, {
        entity: 'User',
        identifier: this.state.user?.id
      });
      return;
    }
    this.setState({ loading: true }, () => {
      if (this.state.following) {
        this.props.unfollow(this.state.user);
      } else {
        this.props.follow(this.state.user);
      }
    });
  };

  render() {
    if (this.props.children instanceof Function) {
      return this.props.children({
        onClick: this.clickHandler,
        loading: this.state.loading,
        following: this.state.following,
        justActed: this.state.justActed
      });
    }
    return <></>;
  }
}

const mapStateToProps = state => ({
  isLoggedIn: state.auth.isLoggedIn,
  followState: state.social.followState,
  localStats: state.user.localStats
});

const mapDispatchToProps = dispatch => ({
  follow: user => dispatch(follow(user)),
  unfollow: user => dispatch(unfollow(user)),
  push: path => dispatch(push(path)),
  expressToggle: (toggle, fromAction) =>
    dispatch(expressToggle(toggle, fromAction))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RTVFollowButton));
